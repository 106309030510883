<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.theme"
              label="退货名称"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入退货名称' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.customer"
              label="客户名称"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择客户' }]"
              @click="showPickerCustomer = true"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.order"
              label="订单"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择订单' }]"
              @click="showPickerOrder= true"
          />
          <van-popup v-model="showPickerOrder" position="bottom">
            <van-picker
                show-toolbar
                :columns="orderList"
                value-key="theme"
                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'theme', 'showPickerOrder')
              "
                @cancel="showPickerOrder = false"
            />
          </van-popup>

          <van-field
              type="number"
              v-model="formInline.customerAmount"
              placeholder="请输入"
              label="客户承担金额"
              @input="handleTotalPrice()"
          />

          <van-field
              v-model="formInline.refundAmount"
              label="退货金额"
              disabled
          />

          <van-field
              readonly
              clickable
              v-model="formInline.typeStr"
              label="退货类别"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择退货类别' }]"
              @click="showPickerType= true"
          />
          <van-popup v-model="showPickerType" position="bottom">
            <van-picker
                show-toolbar
                :columns="typeOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'type', 'typeStr', 'dictLabel', 'showPickerType')
              "
                @cancel="showPickerType = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.leaderStr"
              label="负责人"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择负责人' }]"
              @click="showPop= true"
          />

          <van-field
              readonly
              clickable
              :value="formInline.returnDate"
              label="退货日期"
              placeholder="点击选择日期"
              :required="true"
              :rules="[{ required: true, message: '请选择退货日期' }]"
              @click="showPickerReturnDate = true"
          />
          <van-popup v-model="showPickerReturnDate" style="width: 70%">
            <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                title="请选择退货日期"
                @confirm="onConfirmReturn"
                @cancel="showPickerReturnDate = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.isWarehousingStr"
              label="是否需要出库"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择是否需要出库' }]"
              @click="showPickerWarehousing= true"
          />
          <van-popup v-model="showPickerWarehousing" position="bottom">
            <van-picker
                show-toolbar
                :columns="warehousingOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'isWarehousing', 'isWarehousingStr', 'dictLabel', 'showPickerWarehousing')
              "
                @cancel="showPickerWarehousing = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.refundAccountStr"
              label="退款账户"
              placeholder="请选择"
              @click="showPickerAccount= true"
              :required="true"
              :rules="[{ required: true, message: '请选择退款账户' }]"
          />

          <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

          <div v-for="(item, index) in dataList" :key="index">
            <div class="flex-spa-bet list-tit-wrap">
              <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
            </div>

            <van-field
                v-model="item.materialUnit"
                label="单位"
                disabled
            />

            <van-field
                v-model="item.price"
                label="售价"
                disabled
            />

            <van-field
                v-model="item.returnQuantity"
                label="可退数量"
                disabled
            />

            <van-field
                type="number"
                v-model="item.returnNumber"
                label="退货数量"
                :required="true"
                :rules="[{ required: true, message: '请选择退货数量' }]"
                @input="handleTotalPrice"
            />

            <van-field
                readonly
                clickable
                v-model="item.arrivalDate"
                label="到货日期"
                placeholder="点击选择日期"
                @click="handlePop('showPickerArrival',index)"
            />
            <van-calendar :min-date="minDate" v-model="showPickerArrival"
                          @confirm="onConfirmApply($event)"/>
          </div>

          <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="type != 'detail' && isShowAuth">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
          </van-button>
        </van-form>
      </div>
    </div>

    <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                 @handleClose="handleCloseCustomer"/>

    <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

    <Account :showDialog="showPickerAccount" @handleClose="handleCloseAccount" @submitList="submitList"/>
  </div>
</template>
<script>
import {
  addCrmReturn,
  fetchOrderListByCustomerId,
  getOrderDetails,
  selectItemByReturn,
  updateCrmReturn
} from '@/api/crm/return';
import AppendixVue from '@/views/components/crm/Appendix.vue';
import CustomerPop from '@/components/crm/CustomerPop'
import SelectOneUser from '@/components/selectOneUser/index'
import Account from '@/components/account'
import {changeToCurrentDay, formatDate} from "@/utils/ruoyi";
import {submiteUpload} from "@/api/system/config";

export default {
  components: {AppendixVue, SelectOneUser, CustomerPop, Account},
  data() {
    return {
      formInline: {},
      title: '新建退货',
      id: this.$route.query.id,
      type: this.$route.query.type,
      uploader: [],
      uploadParams: {
        businessType: 'crm_return',
        businessId: undefined,
        file: null,
      },
      currentDate: new Date(),
      minDate: new Date(2010, 0, 1),
      showPickerCustomer: false,
      showPickerArrival: false,
      showPickerType: false,
      showPickerReturnDate: false,
      showPickerOrder: false,
      showPickerAccount: false,
      showPickerWarehousing: false,
      showPop: false,
      //订单数组
      orderList: [],
      //明细数组
      dataList: [],
      //类别数组
      typeOptions: [],
      warehousingOptions: [
        {'dictLabel': '否', 'dictValue': false},
        {'dictLabel': '是', 'dictValue': true}
      ],
      //选中的明细索引
      itemIndex: null,
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    //退货类别字典
    this.getDicts('return_type').then(response => {
      this.typeOptions = response.data
    })
    //回显
    this.findDetail()
  },

  methods: {
    handleGoBack() {
      this.$router.go(-1)
    },

    //回显
    async findDetail() {
      if (this.type == 'add') {
        this.formInline = {
          enterCategory: 0
        };
        this.title = '新建退货';
      } else {
        let itemDetail = await selectItemByReturn({returnId: this.id});
        itemDetail = itemDetail.data
        this.dataList = itemDetail.crmReturnDetails
        this.formInline = itemDetail.crmReturn;
        this.formInline.isWarehousingStr = this.formInline.isWarehousing ? '是' : '否';
        if (itemDetail && this.type == 'edit') {
          this.title = '编辑退货';
        } else {
          this.title = '退货详情';
        }
      }
    },

    //客户提交
    async submitCustomerList(id, name) {
      this.formInline.customerId = id;
      this.formInline.customer = name;

      //改变客户后，表单重置
      this.orderList = []
      this.formInline.orderId = null
      this.formInline.order = ''
      this.formInline.orderPrice = 0
      this.formInline.price = 0
      this.formInline.taxRate = 0
      this.formInline.taxPrice = 0
      const result = await fetchOrderListByCustomerId({
        customerId: id,
        pageNum: 1,
        pageSize: 10000
      })
      this.orderList = result.data.records

      this.showPickerCustomer = false
    },

    //关闭选择客户的弹窗
    handleCloseCustomer() {
      this.showPickerCustomer = false;
    },

    //确认订单
    async onConfirmOrder(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['id'];
      this.formInline[keyName] = event[eventKey];

      const result = await getOrderDetails({orderId: this.formInline.orderId})
      this.dataList = result.data

      this[visible] = false;
    },

    //退货日期确认
    onConfirmReturn(value) {
      this.formInline.returnDate = changeToCurrentDay(value);
      this.showPickerReturnDate = false;
    },

    //选择人提交
    popSubmit(activeId, names) {
      this.showPop = false
      this.formInline.leaderId = activeId
      this.formInline.leaderStr = names
    },

    //关闭选择人
    handleCloseUser() {
      this.showPop = false
    },

    //触发明细弹窗
    handlePop(showPicker, index) {
      this[showPicker] = true;
      this.itemIndex = index
    },

    //日期确认
    onConfirmApply(value) {
      this.dataList[this.itemIndex].arrivalDate = formatDate(value, 'yyyy-MM-dd');
      this.showPickerArrival = false;
    },

    //确认
    async onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    //账户关闭
    handleCloseAccount() {
      this.showPickerAccount = false
    },

    //账户提交
    submitList(id, name) {
      this.formInline.refundAccount = id;
      this.formInline.refundAccountStr = name;
      this.showPickerAccount = false
    },

    //计算金额
    handleTotalPrice() {
      let refundAmount = 0
      this.dataList.forEach(item => {
        refundAmount += ((item.returnNumber ? item.returnNumber : 0) * (item.price ? item.price : 0)).toFixed(2)
      })
      this.formInline.refundAmount = refundAmount - Number(this.formInline.customerAmount ? this.formInline.customerAmount : 0)
    },

    //备注
    handleContentChange(val) {
      this.formInline.remark = val;
    },

    async onSubmit() {
      this.dataList.forEach(item => {
        delete item.createBy
        delete item.createTime
        delete item.updateBy
        delete item.updateTime
        delete item.companyId
        delete item.type
        if (!item.returnNumber) {
          this.$toast.fail('请先填写退货数量');
        }
        if (item.returnNumber > item.returnQuantity) {
          this.$toast.fail('退货数量不能大于可退数量');
        }
        item.costProductId = item.id
      })

      this.formInline.crmReturnDetails = this.dataList
      let data = JSON.stringify(this.formInline)

      const result = this.formInline.id ? await updateCrmReturn(data) : await addCrmReturn(data);
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

.list-tit-wrap {
  padding: 24px 30px;
  font-size: 26px;
  color: #333333;
}

.add-btn {
  width: 750px;
  height: 110px;
  background: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  line-height: 110px;
  font-size: 30px;
  color: #54afff;
  cursor: pointer;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
